<template>
  <div class="rulesScreenContainer">
    <PageHeader v-if="this.$store.state.Auth.autStatus"></PageHeader>

    <section class="label">
      <div class="Instructions">
        <section class="information">
          <h3>{{ $t("rules.title") }}</h3>
          <p>
            {{ $t("rules.information") }}
          </p>
        </section>
        <section class="img">
          <img
            src="https://pragaglobal.com/wp-content/themes/praga/assets/img/home/r1-smalltunel.png"
            alt="racing Car"
          />
        </section>
        <section class="lightPoints">
          <div class="point">
            <img src="https://img.icons8.com/ios/50/null/racing-lap.png" />
            <p>{{ $t("rules.pointOne") }}</p>
          </div>
          <div class="point">
            <img src="https://img.icons8.com/ios/50/null/racing-lap.png" />
            <p>{{ $t("rules.pointTwo") }}</p>
          </div>
          <div class="point">
            <img src="https://img.icons8.com/ios/50/null/racing-lap.png" />
            <p>{{ $t("rules.pointThree") }}</p>
          </div>
          <div class="point">
            <img src="https://img.icons8.com/ios/50/null/racing-lap.png" />
            <p>{{ $t("rules.pointFour") }}</p>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import PageHeader from "@/components/Header.vue";
export default {
  name: "RulesScreen",
  data() {
    return {};
  },
  components: {
    PageHeader,
  },
};
</script>

<style scoped>
@import "../assets/css/views/RulesScreen.css";
</style>
